import "../components/pages/MentionPage.scss";

import { Col, Row } from "react-styled-flexboxgrid";
import Content, { HTMLContent } from "../components/Content";

import Layout from "../components/layout/Layout";
import PropTypes from "prop-types";
import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import SectionTitle from "../components/partials/SectionTitle/SectionTitle.js";
import { StyledGrid } from "../helpers/helpers.js";
import { graphql } from "gatsby";

export const MentionPageTemplate = ({ title, highlighted, content, contentComponent }) => {
  const PageContent = contentComponent || Content;
  return (
    <StyledGrid>
      <section className="MentionPage">
        <ScrollAnimation animateIn="fadeInBottom" duration={1}>
          <Row>
            <SectionTitle text={title} highlight={highlighted} isH1={true} />
            <Col xs={12} md={11} mdOffset={1}>
              <PageContent className="body" content={content} />
            </Col>
          </Row>
        </ScrollAnimation>
      </section>
    </StyledGrid>
  );
};

MentionPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
};

const MentionPage = ({ data }) => {
  const { markdownRemark } = data;
  const post = markdownRemark;
  return (
    <Layout>
      <MentionPageTemplate
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        highlighted={post.frontmatter.highlighted}
        content={post.html}
      />
    </Layout>
  );
};

MentionPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default MentionPage;

export const mentionPageQuery = graphql`
  query MentionPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        highlighted
      }
    }
  }
`;
